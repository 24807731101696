import Box from "@mui/material/Box"
import {alpha, Grid} from "@mui/material"
import theme from "../../styles/theme"
import Typography from "@mui/material/Typography"
import SinDividerWhiteUp from "../../assets/img/sin_divider/sin-divider-white-up.svg"
import HeroAli from "../../assets/img/hero/hero-ali.webp"
import Button from "@mui/material/Button";
import HeroBackgroundImg from '../../assets/img/hero/hero-background.jpeg'
import HeroBackgroundVideo from '../../assets/video/hero-background.mp4'
import {grey, blueGrey} from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SpaIcon from '@mui/icons-material/Spa';
import {scroller} from "react-scroll";


const Hero = () => {
  const scrollToPricing = () => {
    scroller.scrollTo("pricing-scroll-element", {
      duration: 1000,
      delay: 0,
      smooth: "easeInOutQuint",
      offset: -200,
    });
  }

  return <>
    <Box
      sx={{
        textAlign: 'center',
        backgroundImage: `linear-gradient(to right, #37363B, #1A1A24)`,
        // background: '#1A1A24',
        // backgroundColor: theme.palette.primary.main,
      }}
    >
      <Box
        sx={{
          width: {md: '80%'},
          maxWidth: '1300px',
          display: 'inline-block',
          textAlign: 'left',
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12} sm={12} md={8}
            sx={{
              justifyItems: 'left',
              padding: 2,
              paddingTop: {md: 8},
              paddingBottom: {xs: 0},
              textAlign: {xs: 'left', sm: 'left', md: 'left'},
            }}
          >
            <Typography
              sx={{
                fontSize: {xs: 30, sm: 35, md: 46},
                fontWeight: '600',
                color: '#ffffff',
              }}
              variant="h5"
              gutterBottom
            >
              خوشمزه و اصولی <span style={{color: '#C8FF2D'}}>فیت شو!</span>
            </Typography>

            <Box
              sx={{
                fontSize: {xs: 17, sm: 18, md: 22},
              }}
            >
              <Typography
                sx={{
                  color: '#ffffff',
                  fontSize: 'inherit',
                  marginBottom: 3,
                }}
                variant="body1"
                gutterBottom
              >
                ساختن بدن فیت خیلی سادست، اگر از علم و تجربه‌ی متخصصی که خودش این راهو رفته کمک بگیری. من اینجام که کمکت
                کنم خوشمزه و اصولی و نچرال فیت شی! اگه دوست داری تغییرات خودتو شروع کنی و بهترین خودتو بسازی، همراه من باش.
              </Typography>

              {/*<Typography*/}
              {/*  sx={{color: 'text.primary', fontSize: 'inherit'}}*/}
              {/*  variant="subtitle1"*/}
              {/*  gutterBottom*/}
              {/*>*/}
              {/*  - میخوای لاغر بشی؟*/}
              {/*</Typography>*/}

              {/*<Typography*/}
              {/*  sx={{color: 'text.secondary', fontSize: 'inherit'}}*/}
              {/*  variant="body1"*/}
              {/*  gutterBottom*/}
              {/*>*/}
              {/*  خب کم تر غذا بخور، چه کار کنم من؟*/}
              {/*  خب کم تر غذا بخور، چه کار کنم من؟*/}
              {/*  خب کم تر غذا بخور، چه کار کنم من؟*/}
              {/*  خب کم تر غذا بخور، چه کار کنم من؟*/}
              {/*  خب کم تر غذا بخور، چه کار کنم من؟*/}
              {/*  <br/>*/}
              {/*  <br/>*/}
              {/*</Typography>*/}
            </Box>

            <Box
              sx={{
                textAlign: {xs: 'center', sm: 'center', md: 'left'},
              }}
            >
              <Button
                color={'primary'}
                variant="contained"
                size={'large'}
                onClick={scrollToPricing}
                startIcon={<SpaIcon/>}
              >
                سفارش رژیم و برنامه تمرینی
              </Button>
            </Box>
          </Grid>

          <Grid
            item
            xs={12} sm={12} md={4}
            sx={{
              textAlign: 'center',
              marginTop: {xs: 2, sm: 2, md: 10},
              paddingTop: 0,
            }}
          >
            <Box
              sx={{
                width: {xs: '80%', sm: '80%', md: '100%'},
                display: 'inline',
              }}
            >
              <img
                src={HeroAli}
                alt={'اپلیکیشن مستر رژیم'}
                style={{
                  // display: 'inline',
                  width: 'inherit',
                  maxWidth: '400px',
                  paddingBottom: 20,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  </>
}

export default Hero
