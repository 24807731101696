import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import {
  Autocomplete,
  Button, Card, CardContent, CardMedia,
  Chip,
  DialogContent,
  IconButton,
  Link as MuiLink, MenuItem,
  useMediaQuery
} from "@mui/material"
import React, {useEffect, useState} from "react"
import {useTheme} from "@emotion/react"
import {Link, useNavigate} from "react-router-dom"
import CloseIcon from '@mui/icons-material/Close'
import Grid from "@mui/material/Grid"
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator"
import {useGlobalContext} from "../../../contexts/global_context"
import {BACKEND_URL} from "../../../data/config"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography";
import InputFileUpload from "../../utils/InputFileUpload";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import RedoIcon from '@mui/icons-material/Redo';
import SaveIcon from '@mui/icons-material/Save';
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import {getInputValue} from "../../../utils";
import WcOutlinedIcon from "@mui/icons-material/WcOutlined";
import CakeIcon from "@mui/icons-material/Cake";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import HeightIcon from "@mui/icons-material/Height";
import ScaleIcon from "@mui/icons-material/Scale";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import WorkIcon from "@mui/icons-material/Work";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import {hideInputByPlanType} from "../../utils/diet";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import MedicationIcon from "@mui/icons-material/Medication";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import DescriptionIcon from "@mui/icons-material/Description";
import BoyIcon from "@mui/icons-material/Boy";
import Groups3Icon from "@mui/icons-material/Groups3";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import AirlineSeatLegroomExtraIcon from "@mui/icons-material/AirlineSeatLegroomExtra";
import AirlineSeatLegroomReducedIcon from "@mui/icons-material/AirlineSeatLegroomReduced";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";


const ManualDietDetailsDialog = ({uid}) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const {sendAjax, uploadFile, showAlert, isSendingAjax} = useGlobalContext()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [manualDiet, setManualDiet] = useState({})
  const [isLoadingDietFilesButton, setIsLoadingDietFilesButton] = useState(false)
  const [users, setUsers] = useState([])

  const [formValues, setFormValues] = useState({
    // diet details
    first_name: "",
    last_name: "",
    gender: "",
    birthday_year: "",
    city: "",
    height: "",
    weight: "",
    blood_type: "",
    physical_activity: "",
    job: "",
    num_workout_days: "",
    workout_time_range: "",
    want_to_use_supplements: "",
    workout_history: "",
    injuries_and_illness: "",
    food_allergies_and_habits: "",
    drug_use_history: "",
    goal: "",
    shoulder_width: "",
    chest_size: "",
    arm_size: "",
    waist_size: "",
    belly_size: "",
    hip_size: "",
    thigh_size: "",
    shin_size: "",
    workout_place: "",
    description: "",

    // complete
    diet_files: [],
    diet_files_details: [],

    // assign
    diet_plan_user: '',
    workout_plan_user: '',
  })

  const handleUpdateOrder = () => {
    sendAjax({
      url: BACKEND_URL + `/api/v1/diet/admin/manual-diet/update/${uid}/`,
      method: "post",
      data: formValues,
      successMessage: "ویرایش جزئیات، با موفقیت انجام شد.",
    }).then((r) => {
      if (r.data.status_code === 200) {
        fetchOrder()
      }
    })
  }

  const handleChange = (event) => {
    setFormValues({...formValues, [event.target.name]: getInputValue(event.target)})
  }

  const handleComboBoxChange = (name) => (event, value) => {
    setFormValues(formValues => ({...formValues, [name]: value}))
  }

  const completeDiet = () => {
    sendAjax({
      url: `${BACKEND_URL}/api/v1/diet/admin/manual-diet/complete/${uid}/`,
      method: 'post',
      data: formValues,
    }).then(r => {
      if (r.data.status_code === 200) {
        fetchOrder()
      }
    })
  }

  const revertToWaitingForSendData = () => {
    sendAjax({
      url: `${BACKEND_URL}/api/v1/diet/admin/manual-diet/revert-to-waiting-for-send-data/${uid}/`,
      method: 'post',
      data: formValues,
    }).then(r => {
      if (r.data.status_code === 200) {
        fetchOrder()
      }
    })
  }

  const assignDiet = () => {
    sendAjax({
      url: `${BACKEND_URL}/api/v1/diet/admin/manual-diet/assign/${uid}/`,
      method: 'post',
      data: formValues,
    }).then(r => {
      if (r.data.status_code === 200) {
        fetchOrder()
      }
    })
  }

  const handleClose = () => {
    navigate('/admin/manual-diets')
  }

  const handleDietFilesChange = e => {
    setIsLoadingDietFilesButton(true)

    for (let i = 0; i < e.target.files.length; i++) {
      uploadFile({
        file: e.target.files[i],
        url: `${BACKEND_URL}/api/v1/diet/admin/manual-diet/files/upload/${manualDiet.uid}/`,
        successMessage: '',
      }).then(r => {
        if (r.data.status_code === 200) {
          fetchOrder()
        }

        setIsLoadingDietFilesButton(false)
      })
    }
  }

  const deleteDietFile = file_uid => {
    setIsLoadingDietFilesButton(true)

    sendAjax({
      url: `${BACKEND_URL}/api/v1/diet/admin/manual-diet/files/delete/${uid}/${file_uid}/`,
      method: 'post',
      data: {},
    }).then(r => {
      if (r.data.status_code === 200) {
        fetchOrder()
      }

      setIsLoadingDietFilesButton(false)
    })
  }

  const fetchOrder = () => {
    if (uid) {
      setManualDiet({})
      sendAjax({
        url: `${BACKEND_URL}/api/v1/diet/admin/manual-diet/details/${uid}/`,
        method: 'get',
        data: {},
      }).then(r => {
        if (r.data.status_code === 200) {
          setManualDiet(r.data.data)

          setFormValues({
            ...formValues,

            first_name: r.data.data.first_name || '',
            last_name: r.data.data.last_name || '',
            gender: r.data.data.gender || '',
            birthday_year: r.data.data.birthday_year || '',
            city: r.data.data.city || '',
            height: r.data.data.height || '',
            weight: r.data.data.weight || '',
            blood_type: r.data.data.blood_type || '',
            physical_activity: r.data.data.physical_activity || '',
            job: r.data.data.job || '',
            num_workout_days: r.data.data.num_workout_days || '',
            workout_time_range: r.data.data.workout_time_range || '',
            want_to_use_supplements: r.data.data.want_to_use_supplements || '',
            workout_history: r.data.data.workout_history || '',
            injuries_and_illness: r.data.data.injuries_and_illness || '',
            food_allergies_and_habits: r.data.data.food_allergies_and_habits || '',
            drug_use_history: r.data.data.drug_use_history || '',
            goal: r.data.data.goal || '',
            shoulder_width: r.data.data.shoulder_width || '',
            chest_size: r.data.data.chest_size || '',
            arm_size: r.data.data.arm_size || '',
            waist_size: r.data.data.waist_size || '',
            belly_size: r.data.data.belly_size || '',
            hip_size: r.data.data.hip_size || '',
            thigh_size: r.data.data.thigh_size || '',
            shin_size: r.data.data.shin_size || '',
            workout_place: r.data.data.workout_place || '',
            description: r.data.data.description || '',

            diet_plan_user: r.data.data.diet_plan_user,
            workout_plan_user: r.data.data.workout_plan_user,

            diet_files: r.data.data.diet_files,
            diet_files_details: r.data.data.diet_files_details,
          })
        }
      })
    }
  }

  useEffect(() => {
    fetchOrder()

    // get users
    sendAjax({
      url: `${BACKEND_URL}/api/v1/users/staff/users/`,
      method: "get",
      data: {},
    }).then((r) => {
      if (r.data.status_code === 200) {
        setUsers(r.data.data)
      }
    })
  }, [uid])

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth={'md'}
        open={Boolean(uid)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" sx={{backgroundColor: '#37363B',}}>
          جزئیات رژیم و برنامه تمرینی
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            backgroundColor: '#37363B',
          }}
        >
          <Box
            component={ValidatorForm}
            onSubmit={handleUpdateOrder}
            sx={{
              width: '900px',
              maxWidth: '100%',
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <Divider sx={{marginTop: '30px'}}>
                  <Chip label="آپلود فایلهای رژیم و برنامه تمرینی"/>
                </Divider>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <InputFileUpload
                  title={'انتخاب فایلها'}
                  loading={isLoadingDietFilesButton}
                  fullWidth={false}
                  multiple={'multiple'}
                  onChange={handleDietFilesChange}
                />

                <br/>
                <br/>

                <Grid container spacing={1}>
                  {formValues.diet_files_details.map(file_item => {
                    return <Grid item xs={12} sm={12} md={12} key={file_item.uid}>
                      <MuiLink
                        color={'text.primary'}
                        fontSize={'small'}
                        href={`${BACKEND_URL}/api/v1/diet/admin/manual-diet/download/${file_item.uid}/`}
                        target="_blank"
                      >
                        {file_item.name}
                      </MuiLink>

                      <IconButton aria-label="delete" onClick={() => {
                        deleteDietFile(file_item.uid)
                      }}>
                        <DeleteIcon color={'error'} fontSize={'small'}/>
                      </IconButton>
                    </Grid>
                  })}
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <LoadingButton
                  color={'success'}
                  variant="outlined"
                  startIcon={<CheckCircleOutlineIcon/>}
                  disabled={manualDiet.status === 40}
                  onClick={completeDiet}
                  loading={isSendingAjax}
                >
                  تغییر وضعیت به تکمیل شده
                </LoadingButton>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Divider sx={{marginTop: '20px'}}>
                  <Chip label="تخصیص به کارشناس"/>
                </Divider>
              </Grid>

              {manualDiet && [10, 30].includes(manualDiet.plan_type) &&
                <Grid item xs={12} sm={12} md={5}>
                  <Autocomplete
                    disablePortal
                    name={'diet_plan_user'}
                    options={users.map(user => user.uid)}
                    onChange={handleComboBoxChange('diet_plan_user')}
                    value={formValues.diet_plan_user}
                    getOptionLabel={(option) => {
                      let user = users.find((item) => item.uid === option)
                      return user ? `${user.first_name} ${user.last_name}` : ''
                    }}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        name={'diet_plan_user'}
                        label="کارشناس رژیم غذایی"
                        placeholder={'کارشناس رژیم غذایی'}
                        value={formValues.diet_plan_user}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
              }

              {manualDiet && [20, 30].includes(manualDiet.plan_type) &&
                <Grid item xs={12} sm={12} md={5}>
                  <Autocomplete
                    disablePortal
                    name={'workout_plan_user'}
                    options={users.map(user => user.uid)}
                    onChange={handleComboBoxChange('workout_plan_user')}
                    value={formValues.workout_plan_user}
                    getOptionLabel={(option) => {
                      let user = users.find((item) => item.uid === option)
                      return user ? `${user.first_name} ${user.last_name}` : ''
                    }}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        name={'workout_plan_user'}
                        label="کارشناس برنامه تمرینی"
                        placeholder={'کارشناس برنامه تمرینی'}
                        value={formValues.workout_plan_user}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
              }

              <Grid item xs={12} sm={12} md={2}>
                <LoadingButton
                  color={'success'}
                  variant="outlined"
                  startIcon={<CheckCircleOutlineIcon/>}
                  onClick={assignDiet}
                  loading={isSendingAjax}
                >
                  تخصیص
                </LoadingButton>
              </Grid>

              {/*<Grid item xs={12} sm={12} md={12}>*/}
              {/*  <Divider sx={{marginTop: '20px'}}>*/}
              {/*    <Chip label="رژیم‌های قبلی کلاینت"/>*/}
              {/*  </Divider>*/}
              {/*</Grid>*/}

              {/*<Grid item xs={12} sm={12} md={12}>*/}
              {/*  <TableContainer component={Paper}>*/}
              {/*    <Table sx={{minWidth: 650}} aria-label="simple table">*/}
              {/*      <TableHead>*/}
              {/*        <TableRow>*/}
              {/*          <TableCell>شناسه یکتا</TableCell>*/}
              {/*          <TableCell>پلن</TableCell>*/}
              {/*          <TableCell>وضعیت</TableCell>*/}
              {/*        </TableRow>*/}
              {/*      </TableHead>*/}
              {/*      <TableBody>*/}
              {/*        {manualDiet && manualDiet.other_client_diets && manualDiet.other_client_diets.map(otherDiet => {*/}
              {/*          return (*/}
              {/*            <TableRow*/}
              {/*              key={'سبلام'}*/}
              {/*              sx={{'&:last-child td, &:last-child th': {border: 0}}}*/}
              {/*            >*/}
              {/*              <TableCell component="th" scope="row">*/}
              {/*                {'یبیبیبی'}*/}
              {/*              </TableCell>*/}
              {/*              <TableCell>{'یبیب'}</TableCell>*/}
              {/*              <TableCell>{'یبیب'}</TableCell>*/}
              {/*            </TableRow>*/}
              {/*          )*/}
              {/*        })}*/}
              {/*      </TableBody>*/}
              {/*    </Table>*/}
              {/*  </TableContainer>*/}
              {/*</Grid>*/}

              <Grid item xs={12} sm={12} md={12}>
                <Divider sx={{marginTop: '20px'}}>
                  <Chip label="سایر عملیات"/>
                </Divider>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <LoadingButton
                  color={'error'}
                  variant="outlined"
                  startIcon={<RedoIcon/>}
                  onClick={revertToWaitingForSendData}
                  loading={isSendingAjax}
                  disabled={manualDiet && manualDiet.status <= 20}
                >
                  برگشت به وضعیت "منتظر ارسال اطلاعات"
                </LoadingButton>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Divider sx={{marginTop: '30px'}}>
                  <Chip label="جزئیات رژیم"/>
                </Divider>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'شناسه یکتا'}
                  name={'invoice_number'}
                  variant={'outlined'}
                  fullWidth={true}
                  value={manualDiet.invoice_number || '-'}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              {
                manualDiet.mobile ?
                  <Grid item xs={12} sm={12} md={6}>
                    <TextValidator
                      label={'موبایل'}
                      name={'mobile'}
                      variant={'outlined'}
                      fullWidth={true}
                      value={manualDiet.mobile || '-'}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  : ''
              }

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'پلن'}
                  name={'plan_title'}
                  variant={'outlined'}
                  fullWidth={true}
                  value={manualDiet.plan_title || '-'}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'نام'}
                  placeholder={'نام شما'}
                  name={'first_name'}
                  variant={'outlined'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  validators={['required']}
                  errorMessages={['لطفا نام خود وارد کنید.']}
                  onChange={handleChange}
                  value={formValues.first_name}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'نام خانوادگی'}
                  placeholder={'نام خانوادگی شما'}
                  name={'last_name'}
                  variant={'outlined'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  validators={['required']}
                  errorMessages={['لطفا نام خانوادگی خود را وارد کنید.']}
                  onChange={handleChange}
                  value={formValues.last_name}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  select
                  label="جنیست"
                  name={'gender'}
                  variant={'outlined'}
                  fullWidth={true}
                  validators={['required']}
                  errorMessages={['لطفا جنسیت خود را وارد کنید.']}
                  onChange={handleChange}
                  value={formValues.gender}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <WcOutlinedIcon/>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value={10}>{'مرد'}</MenuItem>
                  <MenuItem value={20}>{'زن'}</MenuItem>
                </TextValidator>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'سال تولد'}
                  placeholder={'سال تولد (جهت محاسبه سن)'}
                  name={'birthday_year'}
                  variant={'outlined'}
                  InputProps={{
                    type: 'number',
                    startAdornment: (
                      <InputAdornment position="start">
                        <CakeIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  validators={['required']}
                  errorMessages={['لطفا سال تولد خود را وارد کنید.']}
                  onChange={handleChange}
                  value={formValues.birthday_year}
                />

                {manualDiet.age &&
                  <Typography variant="caption" gutterBottom sx={{display: 'block'}}>
                    سن: {manualDiet.age}
                  </Typography>
                }

              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'شهر'}
                  placeholder={'شهر محل زندگی'}
                  name={'city'}
                  variant={'outlined'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationCityIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  validators={['required']}
                  errorMessages={['لطفا شهر محل زندگی خود را وارد کنید.']}
                  onChange={handleChange}
                  value={formValues.city}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'قد'}
                  placeholder={'قد (سانتی متر)'}
                  name={'height'}
                  variant={'outlined'}
                  InputProps={{
                    type: 'number',
                    startAdornment: (
                      <InputAdornment position="start">
                        <HeightIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  validators={['required']}
                  errorMessages={['لطفا قد خود را به سانتی متر وارد کنید.']}
                  onChange={handleChange}
                  value={formValues.height}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'وزن'}
                  placeholder={'وزن (کیلوگرم)'}
                  name={'weight'}
                  variant={'outlined'}
                  InputProps={{
                    type: 'number',
                    startAdornment: (
                      <InputAdornment position="start">
                        <ScaleIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  validators={['required']}
                  errorMessages={['لطفا وزن خود را به کیلوگرم وارد کنید.']}
                  onChange={handleChange}
                  value={formValues.weight}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  select
                  label="میزان فعالیت روزانه"
                  name={'physical_activity'}
                  variant={'outlined'}
                  fullWidth={true}
                  validators={['required']}
                  errorMessages={['لطفا میزان فعالیت خود را وارد کنید.']}
                  onChange={handleChange}
                  value={formValues.physical_activity}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DirectionsWalkIcon/>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value={10}>{'خیلی کم (کمتر از ۵۰۰۰ قدم در روز)'}</MenuItem>
                  <MenuItem value={20}>{'کم (بین ۵۰۰۰ تا ۷۵۰۰ قدم در روز)'}</MenuItem>
                  <MenuItem value={30}>{'متوسط (بین ۷۵۰۰ تا ۱۰۰۰۰ قدم در روز)'}</MenuItem>
                  <MenuItem value={40}>{'زیاد (بیشتر از ۱۰۰۰۰ قدم در روز)'}</MenuItem>
                  <MenuItem value={50}>{'خیلی زیاد (بیشتر از ۱۲۵۰۰ قدم در روز)'}</MenuItem>
                </TextValidator>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'شغل'}
                  placeholder={'شغل شما چیست؟'}
                  name={'job'}
                  variant={'outlined'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <WorkIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  validators={['required']}
                  errorMessages={['لطفا شغل خود را وارد کنید.']}
                  onChange={handleChange}
                  value={formValues.job}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  select
                  label="تعداد جلسات تمرین در هفته"
                  name={'num_workout_days'}
                  variant={'outlined'}
                  fullWidth={true}
                  validators={['required']}
                  errorMessages={['این فیلد اجباری است.']}
                  onChange={handleChange}
                  value={formValues.num_workout_days}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FitnessCenterIcon/>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value={2}>{'۲ روز'}</MenuItem>
                  <MenuItem value={3}>{'۳ روز'}</MenuItem>
                  <MenuItem value={4}>{'۴ روز'}</MenuItem>
                  <MenuItem value={5}>{'۵ روز'}</MenuItem>
                  <MenuItem value={6}>{'۶ روز'}</MenuItem>
                </TextValidator>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  select
                  label="بازه زمانی تمرین"
                  name={'workout_time_range'}
                  variant={'outlined'}
                  fullWidth={true}
                  onChange={handleChange}
                  value={formValues.workout_time_range}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccessTimeIcon/>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value={'بازه ۶ الی ۹ صبح'}>{'بازه ۶ الی ۹ صبح'}</MenuItem>
                  <MenuItem value={'بازه ۹ الی ۱۲ ظهر'}>{'بازه ۹ الی ۱۲ ظهر'}</MenuItem>
                  <MenuItem value={'بازه ۱۲ الی ۱۵'}>{'بازه ۱۲ الی ۱۵'}</MenuItem>
                  <MenuItem value={'بازه ۱۵ الی ۱۸'}>{'بازه ۱۵ الی ۱۸'}</MenuItem>
                  <MenuItem value={'بازه ۱۸ الی ۲۱'}>{'بازه ۱۸ الی ۲۱'}</MenuItem>
                  <MenuItem value={'بازه ۲۱ الی ۲۳'}>{'بازه ۲۱ الی ۲۳'}</MenuItem>
                </TextValidator>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'سابقه تمرین'}
                  placeholder={'سابقه تمرین خود را وارد کنید'}
                  name={'workout_history'}
                  variant={'outlined'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SportsGymnasticsIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  onChange={handleChange}
                  value={formValues.workout_history}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'آسیب دیدگی و بیماری'}
                  placeholder={'آسیب دیدگی یا بیماری خود را وارد کنید'}
                  name={'injuries_and_illness'}
                  variant={'outlined'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CoronavirusIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  validators={['required']}
                  errorMessages={['در صورتی که آسیب دیدگی و بیماری ندارید، کلمه "ندارم" یا "خیر" را بنویسید.']}
                  onChange={handleChange}
                  value={formValues.injuries_and_illness}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'حساسیت و عادات غذایی'}
                  placeholder={'حساسیت و عادات غذایی خود را وارد کنید'}
                  name={'food_allergies_and_habits'}
                  variant={'outlined'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FastfoodIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  validators={['required']}
                  errorMessages={['لطفا حساسیت و عادات غذایی خود را وارد کنید.']}
                  onChange={handleChange}
                  value={formValues.food_allergies_and_habits}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'سابقه مصرف دارو'}
                  placeholder={'سابقه مصرف دارو'}
                  name={'drug_use_history'}
                  variant={'outlined'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MedicationIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  validators={['required']}
                  errorMessages={['لطفا سابقه مصرف دارو خود را وارد کنید.']}
                  onChange={handleChange}
                  value={formValues.drug_use_history}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  select
                  label="تمایل به مصرف مکمل"
                  name={'want_to_use_supplements'}
                  variant={'outlined'}
                  fullWidth={true}
                  validators={['required']}
                  errorMessages={['تمایل خود به مصرف مکمل را وارد کنید.']}
                  onChange={handleChange}
                  value={formValues.want_to_use_supplements}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ElectricBoltIcon/>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value={'بله'}>بله</MenuItem>
                  <MenuItem value={'خیر'}>خیر</MenuItem>
                </TextValidator>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  select
                  label="محل تمرین"
                  name={'workout_place'}
                  variant={'outlined'}
                  fullWidth={true}
                  onChange={handleChange}
                  value={formValues.workout_place}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SportsGymnasticsIcon/>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value={10}>{'باشگاه'}</MenuItem>
                  <MenuItem value={20}>{'منزل'}</MenuItem>
                </TextValidator>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextValidator
                  label={'هدف'}
                  placeholder={'هدف خود را وارد کنید.'}
                  name={'goal'}
                  variant={'outlined'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DescriptionIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  validators={['required']}
                  errorMessages={['هدف خود را وارد کنید.']}
                  onChange={handleChange}
                  value={formValues.goal}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'عرض شانه'}
                  placeholder={'عرض شانه (سانتی متر)'}
                  name={'shoulder_width'}
                  variant={'outlined'}
                  InputProps={{
                    type: 'number',
                    startAdornment: (
                      <InputAdornment position="start">
                        <BoyIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  onChange={handleChange}
                  value={formValues.shoulder_width}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'دور سینه'}
                  placeholder={'دور سینه (سانتی متر)'}
                  name={'chest_size'}
                  variant={'outlined'}
                  InputProps={{
                    type: 'number',
                    startAdornment: (
                      <InputAdornment position="start">
                        <Groups3Icon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  onChange={handleChange}
                  value={formValues.chest_size}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'دور بازو'}
                  placeholder={'دور بازو (سانتی متر)'}
                  name={'arm_size'}
                  variant={'outlined'}
                  InputProps={{
                    type: 'number',
                    startAdornment: (
                      <InputAdornment position="start">
                        <FitnessCenterIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  onChange={handleChange}
                  value={formValues.arm_size}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'دور کمر'}
                  placeholder={'دور کمر (سانتی متر)'}
                  name={'waist_size'}
                  variant={'outlined'}
                  InputProps={{
                    type: 'number',
                    startAdornment: (
                      <InputAdornment position="start">
                        <TransferWithinAStationIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  onChange={handleChange}
                  value={formValues.waist_size}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'دور شکم'}
                  placeholder={'دور شکم (سانتی متر)'}
                  name={'belly_size'}
                  variant={'outlined'}
                  InputProps={{
                    type: 'number',
                    startAdornment: (
                      <InputAdornment position="start">
                        <SettingsAccessibilityIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  onChange={handleChange}
                  value={formValues.belly_size}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'دور باسن'}
                  placeholder={'دور باسن (سانتی متر)'}
                  name={'hip_size'}
                  variant={'outlined'}
                  InputProps={{
                    type: 'number',
                    startAdornment: (
                      <InputAdornment position="start">
                        <AirlineSeatLegroomExtraIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  onChange={handleChange}
                  value={formValues.hip_size}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'دور ران'}
                  placeholder={'دور ران (سانتی متر)'}
                  name={'thigh_size'}
                  variant={'outlined'}
                  InputProps={{
                    type: 'number',
                    startAdornment: (
                      <InputAdornment position="start">
                        <TransferWithinAStationIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  onChange={handleChange}
                  value={formValues.thigh_size}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'دور ساق پا'}
                  placeholder={'دور ساق پا (سانتی متر)'}
                  name={'shin_size'}
                  variant={'outlined'}
                  InputProps={{
                    type: 'number',
                    startAdornment: (
                      <InputAdornment position="start">
                        <AirlineSeatLegroomReducedIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  onChange={handleChange}
                  value={formValues.shin_size}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextValidator
                  label={'توضیحات'}
                  placeholder={'هدف خود و توضیحات تکمیلی را وارد کنید'}
                  name={'description'}
                  variant={'outlined'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DescriptionIcon/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  onChange={handleChange}
                  value={formValues.description}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <LoadingButton
                  color={'success'}
                  variant="outlined"
                  type="submit"
                  startIcon={<SaveIcon/>}
                  loading={isSendingAjax}
                >
                  ذخیره تغییرات
                </LoadingButton>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Divider sx={{marginTop: '20px'}}>
                  <Chip label="تصاویر"/>
                </Divider>
              </Grid>

              {manualDiet.body_front_image &&
                <Grid item xs={12} sm={12} md={4}>
                  <Card>
                    <CardContent sx={{backgroundColor: '#454545'}}>
                      <img
                        style={{width: '100%'}}
                        src={`${BACKEND_URL}/api/v1/diet/admin/manual-diet/download/${manualDiet.body_front_image}/`}
                      />

                      <Typography gutterBottom variant="body2" component="div">
                        تصویر نمای روبرو
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              }

              {manualDiet.body_back_image &&
                <Grid item xs={12} sm={12} md={4}>
                  <Card>
                    <CardContent sx={{backgroundColor: '#454545'}}>
                      <img
                        style={{width: '100%'}}
                        src={`${BACKEND_URL}/api/v1/diet/admin/manual-diet/download/${manualDiet.body_back_image}/`}
                      />

                      <Typography gutterBottom variant="body2" component="div">
                        تصویر نمای پشت
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              }

              {manualDiet.body_side_image &&
                <Grid item xs={12} sm={12} md={4}>
                  <Card>
                    <CardContent sx={{backgroundColor: '#454545'}}>
                      <img
                        style={{width: '100%'}}
                        src={`${BACKEND_URL}/api/v1/diet/admin/manual-diet/download/${manualDiet.body_side_image}/`}
                      />

                      <Typography gutterBottom variant="body2" component="div">
                        تصویر نمای بغل
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              }

              {manualDiet.body_extra_images && manualDiet.body_extra_images.map(bodyImageUid => {
                return (
                  <Grid item xs={12} sm={12} md={4} key={bodyImageUid}>
                    <Card>
                      <CardContent sx={{backgroundColor: '#454545'}}>
                        <img
                          style={{width: '100%'}}
                          src={`${BACKEND_URL}/api/v1/diet/admin/manual-diet/download/${bodyImageUid}/`}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                )
              })}

            </Grid>
          </Box>
        </DialogContent>

      </Dialog>
    </div>
  )
}

export default ManualDietDetailsDialog
