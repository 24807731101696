import {red} from '@mui/material/colors';
import {createTheme} from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  mode: 'dark',
  palette: {
    success: {
      main: '#80e27e', // Lighter success color
      light: '#b4f8b4', // Even lighter shade
      dark: '#4caf50',  // Darker shade (optional)
      contrastText: '#ffffff', // Text color on success components
    },
    error: {
      main: '#f44336', // Default error color (lighter shade)
      light: '#ff7961', // Lighter version for hover states or accents
      dark: '#ba000d',  // Darker version for active states
      contrastText: '#ffffff', // Text color on error components
    },
    primary: {
      main: '#C8FF2D',
    },
    secondary: {
      main: '#37363B',
      light: '#FFFFFF',
    },
    text: {
      primary: '#fff',
      secondary: '#d8d9d9',
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: '#2c2c2c', // Background color of the opened menu
        },
        listbox: {
          color: '#ffffff', // Text color of the items
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // General Paper styles (affects menus and dropdowns)
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#2c2c2c', // Background color of the menu
          color: '#ffffff',          // Text color
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(200,255,45,0.2)', // Background when selected
          },
          '&:hover': {
            backgroundColor: '#37363B', // Background on hover
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: '#bfc0c0',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#ffffff',
            },
            // '&:hover fieldset': {
            //   borderColor: '#ffffff',
            // },
            // '&.Mui-focused fieldset': {
            //   borderColor: '#ffffff',
            // },
            // backgroundColor: '#ffffff',
          },
          // input: {
          //   color: '#ffffff',
          // },
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'dana',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  direction: 'rtl',
});

export default theme;
