import {Grid} from "@mui/material"
import Typography from "@mui/material/Typography"
import * as React from "react"
// import PricingBox from "./PricingBox"
import {useParams} from "react-router-dom"
import {useEffect, useRef, useState} from "react"
import {useGlobalContext} from "../../contexts/global_context"
import {BACKEND_URL} from "../../data/config"
// import {Element} from 'react-scroll'
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import {Pagination, Navigation} from 'swiper/modules'
import PricingBox from "./PricingBox"
import SinDividerGreyUp from "../../assets/img/sin_divider/sin-divider-grey-up.svg"
import {Element} from 'react-scroll'


const Pricing = ({showTabs = false, groupedCategories = []}) => {
  let {sendAjax} = useGlobalContext()
  let [plans, setPlans] = useState([])
  let categorySlugUrlParam = useParams().categorySlug
  let sliderRef = useRef()
  let [tab, setTab] = useState('')

  useEffect(() => {
    if (true) {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/diet/plans/`,
        method: 'get',
        data: {
          page_size: 1000,
        },
      }).then(r => {
        if (r.data.status_code) {
          setPlans(r.data.data.results)
          // try {
          //   sliderRef.current.swiper.slideToLoop(0)
          // } catch (e) {
          // }
        }
      })
    }
  }, [])

  return (
    <div className={'pricing'}>
      <div className={'pricing-container'}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={6}>
            <div className={'pricing-head'}>
              <Typography variant={'h4'} component={'h1'}>
                هزینه‌ی خدمات
              </Typography>

              <br/>

              <Typography variant="p">
                سه مدل خدمت بهتون ارائه میکنم شامل: پنل رژیم غذایی، پلن برنامه تمرینی و پلن رژیم و تمرین
                 و براساس اهداف تناسب اندامتون میتونید هرکدوم که مدنظرتون هست رو انتخاب کنید.
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Element name="pricing-scroll-element"/>

        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Swiper
              // slidesPerView={3}
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Navigation]}
              className="pricing-swiper"
              // loop={true}
              navigation={true}

              // ref={sliderRef}
              // slidesPerGroup={1}
              // loop={true}
              // loopfillgroupwithblank="false"
              // pagination={{
              //   "clickable": true,
              // }}
              // className="pricing-swiper"
              breakpoints={{
                600: {
                  slidesPerView: 1,
                },
                900: {
                  slidesPerView: 3,
                },
              }}
            >
              {plans.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <PricingBox item={item}/>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Pricing
