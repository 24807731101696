import * as React from 'react';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LoadingButton from "@mui/lab/LoadingButton";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function InputFileUpload({title, loading, fullWidth, ...inputProps}) {
  return (
    <LoadingButton
      component="label"
      role={undefined}
      variant="outlined"
      color={'primary'}
      tabIndex={-1}
      loading={loading}
      fullWidth={fullWidth}
      startIcon={<CloudUploadIcon/>}
    >
      {title}
      <VisuallyHiddenInput type="file" {...inputProps}/>
    </LoadingButton>
  )
}
