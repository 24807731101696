import SinDividerHowItWorks from "../../assets/img/sin_divider/sin-divider-grey-up.svg";
import Box from "@mui/material/Box";
import {alpha, Grid} from "@mui/material";
import {grey} from "@mui/material/colors";
import HowItWorksItem from "./HowItWorksItem";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import SinDividerWhiteUp from "../../assets/img/sin_divider/sin-divider-white-up.svg";
import paymentIcon from "../../assets//img/how_it_works/payment.svg"
import signupIcon from "../../assets/img/how_it_works/signup.svg"
import successIcon from "../../assets/img/how_it_works/success.svg"
import {scroller} from "react-scroll";
import Button from "@mui/material/Button";
import MuiLink from "@mui/material/Link";


const HowItWorks = () => {
  const scrollToPricing = () => {
    scroller.scrollTo("pricing-scroll-element", {
      duration: 1000,
      delay: 0,
      smooth: "easeInOutQuint",
      offset: -200,
    });
  }

  return <>
    <Box className={'how-it-works'}>
      <div className={'how-it-works-container'}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={6}>
            <div className={'our-features-head'}>
              <Typography variant={'h4'} component={'h1'}>
                راهنمای دریافت برنامه
              </Typography>
            </div>
          </Grid>
        </Grid>

        <HowItWorksItem
          title={'۱. اول داخل سایت ثبت نام کن!'}
          description={
            <span>
              در گام اول، پلن مدنظر خود را از
              <Button onClick={scrollToPricing} variant="text" color={'primary'}>این قسمت</Button>
              انتخاب کنید و پرداخت خود را انجام دهید.
              جهت دریافت مشاوره رایگان قبل از ثبت نام (ثبت درخواست برنامه) میتونین به آیدی
               <MuiLink href="https://t.me/mrregym_team"> mrregym_team@ </MuiLink>
              در تلگرام پیام بدین و اگر سوالی دارید بپرسید و بعد با خیال راحت درخواست خود را در سایت ثبت کنید.
            </span>
          }
          icon={signupIcon}
          index={0}
        />

        <Divider variant="middle"/>

        <HowItWorksItem
          title={'۲. مشخصات فردی خود را وارد کنید'}
          description={
            'بعد از ثبت نام و انتخاب پلن مورد نظر و پرداخت هزینه، پرسشنامه اطلاعات فردی را کامل کنید' +
            ' و درخواست خودرا نهایی کنید. (درصورت عدم تکمیل پرسشنامه بلافاصله بعد از پرداخت در اسرع وقت نسبت ب' +
            'ه تکمیل پرسشنامه به صورت کامل اقدام کنید.)'
          }
          icon={paymentIcon}
          index={1}
        />

        <Divider variant="middle"/>

        <HowItWorksItem
          title={'۳. شروع کن به ساختن بدن دلخواهت!'}
          description={
            'بعد از تکمیل ثبت نام و ثبت کامل اطلاعات فردی، برنامه شما رو ظرف ۵ الی ۷ روز کاری طراحی میکنم و' +
            ' داخل داشبوردتون در سایت بارگذاری میکنم و میتونید برنامتون رو در قالب فایل پی دی اف دانلود کنید و با قدرت استارت ساختن اندام دلخواهتون رو بزنید 💪'
          }
          icon={successIcon}
          index={2}
        />
      </div>

    </Box>
  </>
}


export default HowItWorks
