import Typography from "@mui/material/Typography"
import * as React from "react"
import {Link} from "react-router-dom"
import MuiLink from '@mui/material/Link'

const FooterCol = ({title, items}) => {
  return (
    <div className={'footer-col'}>
      <Typography variant="h6" component="div">
        {title}
      </Typography>

      {items &&
        items.map(item => {
          return (
            <Typography
              key={item.uid}
              sx={{
                margin: '10px 0 10px 0',
              }}
              variant="body2"
              component="div"
            >
              <MuiLink
                sx={{
                  textDecoration: 'none',
                }}
                component={Link}
                to={item.slug}
              >
                {item.title}
              </MuiLink>
            </Typography>
          )
        })}
    </div>
  )
}

export default FooterCol
