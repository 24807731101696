import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import * as React from "react"
import Button from "@mui/material/Button"
import {Link} from "react-router-dom"
import SinDividerWhiteUp from "../../assets/img/sin_divider/sin-divider-white-up.svg"
import Grid from "@mui/material/Grid";
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';


const PricingBox = ({item}) => {
  const title1 = item.title.split(' ')[0]
  const title2 = item.title.split(' ').slice(1).join(' ')

  return (
    <div className={'pricing-box'}>
      {/*<div className={'pricing-box-discount'}>*/}
      {/*  {item.discount}% OFF*/}
      {/*</div>*/}

      <Paper
        elevation={0}
        sx={{
          background: `linear-gradient(to right, #454545, #45454500)`,
          borderRadius: '16px 72px 16px 16px',
        }}
      >
        <div className={'pricing-box-content'}>
          <Typography variant="h5" component="div" color={'primary'}>
            {item.title}
          </Typography>

          <div className={'pricing-box-content-line'}>
            <span className={'pricing-box-content-line-text'}>
              ● {item.descriptions[0]}
            </span>
          </div>

          <div className={'pricing-box-content-line'}>
            <span className={'pricing-box-content-line-text'}>
              ● {item.descriptions[1]}
            </span>
          </div>

          <div className={'pricing-box-content-line'}>
            <span className={'pricing-box-content-line-text'}>
              ● {item.descriptions[2]}
            </span>
          </div>

          <div className={'pricing-box-content-line'}>
            <span className={'pricing-box-content-line-text'}>
              ● {item.descriptions[3]}
            </span>
          </div>

          <div className={'pricing-box-content-line'}>
            <span className={'pricing-box-content-line-text'}>
              ● {item.descriptions[4]}
            </span>
          </div>

          <Typography className={'pricing-box-price'} variant="h5" component="div">
            {item.price.toLocaleString()} تومان
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6}>
              <Button
                startIcon={<ShoppingBasketOutlinedIcon/>}
                component={Link}
                to={`/dashboard/checkout/${item.uid}`}
                className={'pricing-box-buy-button'}
                variant="contained"
                size="large"
                fullWidth={true}
                color={'primary'}
              >
                خرید
              </Button>
            </Grid>

            <Grid item xs={6} sm={6} md={6}>
              <Button
                startIcon={<HelpOutlineOutlinedIcon/>}
                component={Link}
                to={`https://t.me/mrregym_team`}
                className={'pricing-box-buy-button'}
                variant="contained"
                size="large"
                fullWidth={true}
                color={'primary'}
              >
                مشاوره
              </Button>
            </Grid>

          </Grid>
        </div>
      </Paper>
    </div>
  )
}

export default PricingBox
