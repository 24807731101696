import {Divider} from "@mui/material"
import Typography from "@mui/material/Typography"
import * as React from "react"
import {Link, useNavigate, useParams} from "react-router-dom"
import MuiLink from '@mui/material/Link'
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import InputAdornment from "@mui/material/InputAdornment"
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import SinDividerGreyUp from "../../../assets/img/sin_divider/sin-divider-grey-up.svg"
import {useEffect, useRef, useState} from "react"
import {grey} from "@mui/material/colors"
import LockOpenIcon from '@mui/icons-material/LockOpen'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator"
import LoadingButton from "@mui/lab/LoadingButton"
import CustomerOrderDietForm from "./CustomerOrderDietForm"
import {useGlobalContext} from "../../../contexts/global_context";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {BACKEND_URL} from "../../../data/config";
import {scroller} from "react-scroll";


const CustomerOrderDiet = () => {
  let form = useRef()
  const {executeRecaptcha} = useGoogleReCaptcha()
  const navigate = useNavigate()
  let {dietUid} = useParams()
  let {
    sendAjax,
    isSendingAjax,
    setIsSendingAjax,
    sendingAjaxUrls,
    userInfo,
    setUserInfo,
    showAlert
  } = useGlobalContext()
  let [plan, setPlan] = useState({})
  let [diet, setDiet] = useState({})

  let [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    gender: "",
    birthday_year: "",
    city: "",
    height: "",
    weight: "",
    blood_type: "",
    physical_activity: "",
    job: "",
    num_workout_days: "",
    workout_time_range: "",
    want_to_use_supplements: "",
    workout_history: "",
    injuries_and_illness: "",
    food_allergies_and_habits: "",
    drug_use_history: "",
    goal: "",
    shoulder_width: "",
    chest_size: "",
    arm_size: "",
    waist_size: "",
    belly_size: "",
    hip_size: "",
    thigh_size: "",
    shin_size: "",
    workout_place: "",
    description: "",
    hide_images: false,
    body_front_image: null,
    body_back_image: null,
    body_side_image: null,
    body_extra_images: [],
  })

  const handleSubmit = (e) => {
    if (!executeRecaptcha) {
      return
    }

    // check if all files uploaded?
    if (!formValues.hide_images) {
      if (!formValues.body_front_image || !formValues.body_back_image || !formValues.body_side_image) {
        showAlert(
          'تصاویر به صورت کامل آپلود نشده اند.',
          'error',
        )
        return
      }
    }

    setIsSendingAjax(true)

    executeRecaptcha('ORDER_DIET').then(gr => {
      setIsSendingAjax(true)

      sendAjax({
        url: BACKEND_URL + `/api/v1/diet/customer/manual-diet/update/${dietUid}/`,
        method: "post",
        data: {
          ...formValues,
          gr: gr,
        },
        successMessage: "درخواست شما با موفق ثبت شد و در لیست بررسی قرار گرفت. لطفا تا آماده سازی آن شکیبا باشید.",
      }).then((r) => {
        setIsSendingAjax(false)

        if (r.data.status_code === 200) {
          navigate("/dashboard", {replace: true})
          window.scrollTo(0, 0)
        }
      })
    })
  }

  useEffect(() => {
    sendAjax({
      url: `${BACKEND_URL}/api/v1/diet/customer/manual-diet/details/${dietUid}/`,
      method: "get",
      data: {},
      successMessage: "",
    }).then((r) => {
      if (r.data.status_code === 200) {
        setDiet(r.data.data)

        setFormValues({
          ...formValues,
          first_name: r.data.data.first_name || '',
          last_name: r.data.data.last_name || '',
          gender: r.data.data.gender || '',
          birthday_year: r.data.data.birthday_year || '',
          city: r.data.data.city || '',
          height: r.data.data.height || '',
          weight: r.data.data.weight || '',
          blood_type: r.data.data.blood_type || '',
          physical_activity: r.data.data.physical_activity || '',
          job: r.data.data.job || '',
          num_workout_days: r.data.data.num_workout_days || '',
          workout_time_range: r.data.data.workout_time_range || '',
          want_to_use_supplements: r.data.data.want_to_use_supplements || '',
          workout_history: r.data.data.workout_history || '',
          injuries_and_illness: r.data.data.injuries_and_illness || '',
          food_allergies_and_habits: r.data.data.food_allergies_and_habits || '',
          drug_use_history: r.data.data.drug_use_history || '',
          goal: r.data.data.goal || '',
          shoulder_width: r.data.data.shoulder_width || '',
          chest_size: r.data.data.chest_size || '',
          arm_size: r.data.data.arm_size || '',
          waist_size: r.data.data.waist_size || '',
          belly_size: r.data.data.belly_size || '',
          hip_size: r.data.data.hip_size || '',
          thigh_size: r.data.data.thigh_size || '',
          shin_size: r.data.data.shin_size || '',
          workout_place: r.data.data.workout_place || '',
          description: r.data.data.description || '',
        })
      }
    })
  }, []);

  const handleError = (errors) => {
    if (errors && errors.length > 0) {
      scroller.scrollTo(errors[0].props.name, {
        duration: 1000,
        delay: 0,
        smooth: "easeInOutQuint",
        offset: -200,
      });
    }
  }

  return <>
    {/* SEO */}
    {/*<Helmet>*/}
    {/*  <title>Sign Up - LikeTank</title>*/}
    {/*  <meta name="description" content={DEFAULT_META_DESCRIPTION}/>*/}
    {/*</Helmet>*/}

    <div className={'login'}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={6}>
          <Paper className={'login-container'} elevation={5} sx={{backgroundColor: '#454545'}}>
            <div className={'login-header'}>
              <Typography variant="h4" component="h1" gutterBottom>
                دریافت رژیم و برنامه تمرینی
              </Typography>

              <Typography color={'text.secondary'} variant="body1" gutterBottom>
                برای دریافت رژیم و برنامه تمرینی اختصاصی، فرم زیر رو کامل کن!
              </Typography>
            </div>

            <Divider/>

            <div className={'login-content'}>
              <Box
                component={ValidatorForm}
                ref={form}
                onSubmit={handleSubmit}
                onError={handleError}
                debounceTime={1500}
                sx={{
                  '& .MuiTextField-root': {marginBottom: '30px'},
                }}
              >
                <CustomerOrderDietForm {...{
                  formValues,
                  setFormValues,
                  planType: diet.plan_type,
                }}/>
              </Box>
            </div>
          </Paper>
        </Grid>
      </Grid>

    </div>
  </>
}


export default CustomerOrderDiet
