import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Card, CardContent, Grid} from "@mui/material";
import trayIcon from "../../assets/img/our_features/tray.png"
import dishIcon from "../../assets/img/our_features/dish.png"
import firstCustomerResults from "../../assets/img/customer_results/first.png"
import secondCustomerResults from "../../assets/img/customer_results/second.JPG"
import thirdCustomerResults from "../../assets/img/customer_results/third.JPG"
import SinDividerGreyUp from "../../assets/img/sin_divider/sin-divider-grey-up.svg";


const CustomerResults = () => {
  return (
    <Box
      className={'main-container'}
      sx={{
        backgroundColor: '#37363B',
      }}
    >
      <Box
        className={'main-sub-container'}
        sx={{
          textAlign: 'center',
          paddingTop: 5,
          paddingRight: 6,
          paddingBottom: 6,
          paddingLeft: 6,
        }}
      >
        <Typography
          variant={'h4'}
          sx={{
            fontWeight: '600',
            paddingBottom: 1,
            color: '#ffffff',
          }}
        >
          اینجا <span style={{color: '#C8FF2D'}}>همه نچرالن!</span>
        </Typography>

        <Typography
          variant={'body1'}
          sx={{
            // fontWeight: '600',
            paddingBottom: 5,
            display: 'inline-block',
            maxWidth: '500px',
            color: '#ffffff',
          }}
        >
          بدنسازی وقتی خوبه که نچرال باشه، یعنی بدون استفاده از دارو (انواع داروهای استروئیدی، پپتیدی، سارم، چربی سوز و...) فقط با رژیم غذایی و تمرینات ورزشی و مصرف مکمل‌های مجاز داروخانه‌ای (اونم در صورت تمایل)
        </Typography>

        <Grid container>
          <Grid
            item
            xs={12} sm={12} md={4}
            className={'customer-results-grid-item'}
          >
            <Card className={'customer-results-card'}>
              <CardContent>
                <img
                  src={secondCustomerResults}
                  alt={'Tray'}
                  className={'customer-results-image'}
                />

                <Typography variant={'h6'} className={'customer-results-title'}>
                  اول باغچه خودمو بیل زدم 😎
                </Typography>

                <Typography variant={'body2'}>
                  اول از همه تغییرات نچرال خودمو ببینید. نه مربی داشتم، نه کسی که تو این مسیر کمکم کنه. برای همین کلی آزمون و خطا و مطالعه کردم تا سریع ترین و راحت ترین و در عین حال علمی و اصولی ترین راه رو برای ساختن بدنم پیدا کردم.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            xs={12} sm={12} md={4}
            className={'customer-results-grid-item'}
          >
            <Card className={'customer-results-card'}>
              <CardContent>
                <img
                  src={firstCustomerResults}
                  alt={'Tray'}
                  className={'customer-results-image'}
                />

                <Typography variant={'h6'} className={'customer-results-title'}>
                  تغییرات مهدی در ۶ ماه!
                </Typography>

                <Typography variant={'body2'}>
                  مهدی قبل از کار کردن با من، دو سال مستمر سابقه تمرین و رژیم داشت و باشگاه میرفت، اما نتیجه نگرفت و از تغییر کردن نا امید شده بود. ولی وقتی که از راه درست و علمی رفت جلو، فقط با ۶ ماه رژیم و تمرین اصولی، خیلی راحت تر و سریع تر تونست این همه تغییر کنه 💪
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            xs={12} sm={12} md={4}
            className={'customer-results-grid-item'}
          >
            <Card className={'customer-results-card'}>
              <CardContent>
                <img
                  src={thirdCustomerResults}
                  alt={'Tray'}
                  className={'customer-results-image'}
                />

                <Typography variant={'h6'} className={'customer-results-title'}>
                  تغییرات علی رضا در ۹ ماه!
                </Typography>

                <Typography variant={'body2'}>
                  علیرضا از اون ورزشکارایی بود که همیشه تو رژیم بود و باشگاه میرفت ولی تغییر نمیکرد و کلی هزینه و زمان و انرژیش داشت از بین می‌رفت. احتمالا هممون یه همچین دوستی داریم. خلاصه، اومد راه درستش رو رفت و ۹ ماهه کارو در آورد!
                </Typography>
              </CardContent>
            </Card>
          </Grid>

        </Grid>
      </Box>
    </Box>
  )
}

export default CustomerResults
