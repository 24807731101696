import React from 'react';
import { Telegram } from '@mui/icons-material';
import {Chip} from "@mui/material"; // Or replace with a custom icon/image

const TelegramButton = () => {
  return (
    <div style={styles.container}>
      <div style={styles.text}>
        <Chip label="مشاوره / پشتیبانی" sx={{backgroundColor: '#e0e0e0'}} color="primary"/>
      </div>
      <a
        href="https://t.me/mrregym_team" // Telegram link
        // target="_blank" // Open in a new tab
        rel="noopener noreferrer" // Security best practices
        style={styles.link} // Remove default anchor styles
      >
        <button style={styles.button}>
          <Telegram style={styles.icon} />
        </button>
      </a>
    </div>
  );
};

const styles = {
  container: {
    // Position the button and text in the bottom-left corner
    position: 'fixed',
    bottom: '20px',
    left: '20px', // Button is aligned to the left
    display: 'flex',
    flexDirection: 'column', // Place the text above the icon
    alignItems: 'center',
    zIndex: 1000, // Ensure it appears above other elements
  },
  text: {
    // Style for the text above the icon
    marginBottom: '5px', // Space between text and button
    fontSize: '14px',
    color: '#0077b5', // Telegram blue for text
    fontWeight: 'bold',
    textAlign: 'center',
  },
  link: {
    // Remove default link styles for better appearance
    textDecoration: 'none',
  },
  button: {
    // Style for the circular button
    backgroundColor: '#0088cc', // Telegram blue for the button
    border: 'none',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Subtle shadow for effect
    cursor: 'pointer', // Pointer cursor for button
  },
  icon: {
    // Style for the Telegram icon inside the button
    color: 'white',
    fontSize: '30px',
  },
};

export default TelegramButton;
