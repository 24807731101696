import {useGlobalContext} from "../../../contexts/global_context"
import {useEffect, useRef, useState} from "react"
import {Link, useParams} from "react-router-dom"
import {BACKEND_URL} from "../../../data/config"
import {Card, CardHeader, Divider, Grid} from "@mui/material"
import Typography from "@mui/material/Typography"
import * as React from "react"
import Button from "@mui/material/Button"
import LoadingButton from "@mui/lab/LoadingButton";
import SinDividerGreyUp from "../../../assets/img/sin_divider/sin-divider-grey-up.svg";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";


const CustomerCheckoutDiet = () => {
  let {sendAjax, isSendingAjax, setIsSendingAjax} = useGlobalContext()
  let [plans, setPlans] = useState([])
  let {planUid} = useParams()
  let sliderRef = useRef()
  let [tab, setTab] = useState('')
  const {executeRecaptcha} = useGoogleReCaptcha()

  const goToGateway = () => {
    setIsSendingAjax(true)

    executeRecaptcha('CHECKOUT').then(gr => {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/payments/zarinpal/go-to-gateway/${planUid}/`,
        method: 'post',
        data: {
          gr: gr,
        },
        successMessage: 'در حال انتقال به درگاه پرداخت...',
      }).then(r => {
        if (r.data.status_code === 200) {
          window.location.href = r.data.data.redirect_url
        }
      })
    })

  }

  useEffect(() => {
    // if (true) {
    //   sendAjax({
    //     url: `${BACKEND_URL}/api/v1/diet/plans/`,
    //     method: 'get',
    //     data: {
    //       page_size: 1000,
    //     },
    //   }).then(r => {
    //     if (r.data.status_code) {
    //       setPlans(r.data.data.results)
    //       // try {
    //       //   sliderRef.current.swiper.slideToLoop(0)
    //       // } catch (e) {
    //       // }
    //     }
    //   })
    // }
  }, [])

  return (
    <div className={'checkout-diet'}>
      <div className={'checkout-diet-container'}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={6}>
            <Card className={'checkout-diet-card'} raised={true} sx={{background: '#454545'}}>
              <Typography variant={'h4'} component={'h1'}>
                پرداخت هزینه
              </Typography>

              <br/>
              <Divider/>

              <Typography variant="body1" sx={{textAlign: 'left', padding: '20px 0 20px 0'}}>
                ✅ طراحی تمام پلن‌ها به صورت شخصی سازی شده متناسب با هدف و شرایط بدنی شما انجام میشود.

                <br/>
                <br/>

                ✅ تیم پشتیبانی مستر رژیم به صورت ۲۴ ساعته از طریق تلگرام به اعضای خود پشتیبانی میدهد و پاسخگوی تمام
                سوالات و مشکلات شما خواهد بود.

                <br/>
                <br/>

                ✅ در طراحی پلن رژیم غذایی، رژیم بر اساس سفره خانواده ایرانی و غذاهای مورد علاقه شما تنظیم میشوند. (در
                صورت تمایل، برنامه مصرف مکمل، همراه رژیم خدمتتون ارائه میشود.)

                <br/>
                <br/>

                ✅ در طراحی پلن تمرین، برنامه تمرینی به انتخاب خودتون در منزل یا باشگاه با توجه به شرایط بدنی شما، سابقه
                تمرینی و اهدام تناسب اندام شما طراحی میشوند و ویدیو آموزش حرکات به همراه نکات و جزئیات اجرای حرکت ارائه
                میشوند.


              </Typography>

              <LoadingButton
                className={'pricing-box-buy-button'}
                variant="contained"
                size="large"
                fullWidth={true}
                color={'primary'}
                loading={isSendingAjax || !Boolean(executeRecaptcha)}
                onClick={goToGateway}
              >
                پرداخت هزینه پلن
              </LoadingButton>


            </Card>
          </Grid>
        </Grid>

      </div>
    </div>
  )
}

export default CustomerCheckoutDiet
