import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import TablePagination from "@mui/material/TablePagination"

const AdminTable = ({items, numTotal, page, setPage}) => {
  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }

  let tableHead = <></>
  if (items.length) {
    tableHead = Object.keys(items[0])
      .slice(1)
      .map((item) => {
        return <TableCell key={Math.random()}>{item}</TableCell>
      })
  }

  return (
    <>
      <TableContainer component={Paper} sx={{backgroundColor: '#454545'}}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>{tableHead}</TableRow>
          </TableHead>
          <TableBody>
            {items.map((row) => {
              return (
                <TableRow
                  key={row.uid}
                  sx={{"&:last-child td, &:last-child th": {border: 0}}}
                >
                  {Object.values(row)
                    .slice(1)
                    .map((item) => {
                      return <TableCell key={Math.random()}>{item}</TableCell>
                    })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={numTotal}
        rowsPerPage={10}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[]}
        sx={{marginBottom: 10}}
        labelDisplayedRows={function defaultLabelDisplayedRows({from, to, count}) {
          return `${from}–${to} از ${count !== -1 ? count : `بیشتر از ${to}`}`;
        }}
      />
    </>
  )
}

export default AdminTable
