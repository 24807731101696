import NavbarSidebar from "../../components/header/NavbarSidebar"
import Footer from "../../components/footer/Footer"
import {Card, Divider} from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {grey} from "@mui/material/colors";
import Box from "@mui/material/Box";
import {ValidatorForm} from "react-material-ui-form-validator";
import SinDividerGreyUp from "../../assets/img/sin_divider/sin-divider-grey-up.svg";
import * as React from "react";
import MuiLink from "@mui/material/Link";


const VisitPage = () => {
  return <>
    <NavbarSidebar/>

    <div className={'login'}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={6}>
          <Paper className={'login-container'} elevation={5} sx={{backgroundColor: '#454545'}}>
            <div className={'login-header'}>
              <Typography variant="h4" component="h1" gutterBottom>
                ویزیت حضوری
              </Typography>

              <br/>
              <Divider/>
              <br/>

              <Typography variant="body1" gutterBottom>
                🟠 جهت هماهگی و رزرو ویزیت حضوری در کلینیک (تهران)، با
                <MuiLink href="https://wa.me/989368227312"> شماره واستاپ 09368227312 </MuiLink>
                در تماس باشین 👍

                <br/>
                <br/>

                آدرس کلینیک: تهران، چهار راه کامرانیه، نبش اندرزگو، برج دریای نور، طبقه ۶، واحد ۶۰۲
              </Typography>
            </div>

            <Divider/>
          </Paper>
        </Grid>
      </Grid>
    </div>

    <Footer/>
  </>
}

export default VisitPage
