import InputAdornment from "@mui/material/InputAdornment"
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid"
import {TextValidator} from "react-material-ui-form-validator"
import * as React from "react"
import LoadingButton from "@mui/lab/LoadingButton"
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft"
import {BACKEND_URL} from "../../../data/config"
import {useGlobalContext} from "../../../contexts/global_context"
import {getFieldErrorParams, getInputValue} from "../../../utils"
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup
} from "@mui/material"
import ImagePicker from "../../utils/ImagePicker"
import bodyImageFront from "../../../assets/img/customer_panel/body_images/body_image_front.png"
import bodyImageSide from "../../../assets/img/customer_panel/body_images/body_image_side.png"
import bodyImageBack from "../../../assets/img/customer_panel/body_images/body_image_back.png"
import PersonIcon from '@mui/icons-material/Person'
import WcOutlinedIcon from '@mui/icons-material/WcOutlined'
import CakeIcon from '@mui/icons-material/Cake'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import HeightIcon from '@mui/icons-material/Height'
import ScaleIcon from '@mui/icons-material/Scale'
import BloodtypeIcon from '@mui/icons-material/Bloodtype'
import WorkIcon from '@mui/icons-material/Work'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics'
import CoronavirusIcon from '@mui/icons-material/Coronavirus'
import MedicationIcon from '@mui/icons-material/Medication'
import BoyIcon from '@mui/icons-material/Boy'
import Groups3Icon from '@mui/icons-material/Groups3'
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility'
import AirlineSeatLegroomExtraIcon from '@mui/icons-material/AirlineSeatLegroomExtra'
import AirlineSeatLegroomReducedIcon from '@mui/icons-material/AirlineSeatLegroomReduced'
import DescriptionIcon from '@mui/icons-material/Description'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import FastfoodIcon from '@mui/icons-material/Fastfood'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import {hideInputByPlanType} from "../../utils/diet";
import {useRef, useState} from "react";
import imageCompression from "browser-image-compression"
import InputFileUpload from "../../utils/InputFileUpload";
import Typography from "@mui/material/Typography";


const CustomerOrderDietForm = ({formValues, setFormValues, planType}) => {
  let {sendAjax, isSendingAjax, uploadFile, sendingAjaxUrls} = useGlobalContext()
  let [showBodyImages, setShowBodyImages] = useState(true)
  let [uploadingExtraImages, setUploadingExtraImages] = useState([])

  const imageCompressorOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 2000,
    useWebWorker: true,
    fileType: "image/jpeg",
  }

  const handleChange = (event) => {
    setFormValues({...formValues, [event.target.name]: getInputValue(event.target)})
  }

  const handleFileUpload = (e) => {
    let file = e.target.files[0]

    imageCompression(file, imageCompressorOptions).then((img) => {
      uploadFile({
        url: `${BACKEND_URL}/api/v1/diet/customer/manual-diet/files/upload/`,
        file: img,
        successMessage: "",
      }).then(r => {
        if (r.data.status_code === 200) {
          setFormValues(formValues => ({...formValues, [e.target.name]: r.data.data.uid}))
        }
      })
    })
  }

  const handleExtraBodyImagesChange = e => {
    for (let i = 0; i < e.target.files.length; i++) {
      let file = e.target.files[i]
      uploadingExtraImages.push(file.name)
      setUploadingExtraImages(uploadingExtraImages)

      imageCompression(file, imageCompressorOptions).then((img) => {
        uploadFile({
          url: `${BACKEND_URL}/api/v1/diet/customer/manual-diet/files/upload/?extra_body_image=${i}`,
          file: img,
          successMessage: "",
        }).then(r => {
          uploadingExtraImages = uploadingExtraImages.filter(item => item !== file.name)
          setUploadingExtraImages(uploadingExtraImages)

          if (r.data.status_code === 200) {
            setFormValues(
              formValues => ({...formValues, body_extra_images: formValues.body_extra_images.concat([r.data.data.uid])})
            )
          }
        })
      })
    }

  }

  return <>
    <TextValidator
      label={'نام'}
      placeholder={'نام شما'}
      name={'first_name'}
      variant={'outlined'}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PersonIcon/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      validators={['required']}
      errorMessages={['لطفا نام خود وارد کنید.']}
      onChange={handleChange}
      value={formValues.first_name}
    />

    <TextValidator
      label={'نام خانوادگی'}
      placeholder={'نام خانوادگی شما'}
      name={'last_name'}
      variant={'outlined'}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PersonIcon/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      validators={['required']}
      errorMessages={['لطفا نام خانوادگی خود را وارد کنید.']}
      onChange={handleChange}
      value={formValues.last_name}
    />

    <TextValidator
      select
      label="جنیست"
      name={'gender'}
      variant={'outlined'}
      fullWidth={true}
      validators={['required']}
      errorMessages={['لطفا جنسیت خود را وارد کنید.']}
      onChange={handleChange}
      value={formValues.gender}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <WcOutlinedIcon/>
          </InputAdornment>
        ),
      }}
    >
      <MenuItem value={10}>{'مرد'}</MenuItem>
      <MenuItem value={20}>{'زن'}</MenuItem>
    </TextValidator>

    <TextValidator
      label={'سال تولد'}
      placeholder={'سال تولد (جهت محاسبه سن)'}
      name={'birthday_year'}
      variant={'outlined'}
      InputProps={{
        type: 'number',
        startAdornment: (
          <InputAdornment position="start">
            <CakeIcon/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      validators={['required']}
      errorMessages={['لطفا سال تولد خود را وارد کنید.']}
      onChange={handleChange}
      value={formValues.birthday_year}
    />

    <TextValidator
      label={'شهر'}
      placeholder={'شهر محل زندگی'}
      name={'city'}
      variant={'outlined'}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LocationCityIcon/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      validators={['required']}
      errorMessages={['لطفا شهر محل زندگی خود را وارد کنید.']}
      onChange={handleChange}
      value={formValues.city}
    />

    <TextValidator
      label={'قد'}
      placeholder={'قد (سانتی متر)'}
      name={'height'}
      variant={'outlined'}
      InputProps={{
        type: 'number',
        startAdornment: (
          <InputAdornment position="start">
            <HeightIcon/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      validators={['required']}
      errorMessages={['لطفا قد خود را به سانتی متر وارد کنید.']}
      onChange={handleChange}
      value={formValues.height}
    />

    <TextValidator
      label={'وزن'}
      placeholder={'وزن (کیلوگرم)'}
      name={'weight'}
      variant={'outlined'}
      InputProps={{
        type: 'number',
        startAdornment: (
          <InputAdornment position="start">
            <ScaleIcon/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      validators={['required']}
      errorMessages={['لطفا وزن خود را به کیلوگرم وارد کنید.']}
      onChange={handleChange}
      value={formValues.weight}
    />

    <TextValidator
      select
      label="میزان فعالیت روزانه"
      name={'physical_activity'}
      variant={'outlined'}
      fullWidth={true}
      validators={['required']}
      errorMessages={['لطفا میزان فعالیت خود را وارد کنید.']}
      onChange={handleChange}
      value={formValues.physical_activity}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <DirectionsWalkIcon/>
          </InputAdornment>
        ),
      }}
    >
      <MenuItem value={10}>{'خیلی کم (کمتر از ۵۰۰۰ قدم در روز)'}</MenuItem>
      <MenuItem value={20}>{'کم (بین ۵۰۰۰ تا ۷۵۰۰ قدم در روز)'}</MenuItem>
      <MenuItem value={30}>{'متوسط (بین ۷۵۰۰ تا ۱۰۰۰۰ قدم در روز)'}</MenuItem>
      <MenuItem value={40}>{'زیاد (بیشتر از ۱۰۰۰۰ قدم در روز)'}</MenuItem>
      <MenuItem value={50}>{'خیلی زیاد (بیشتر از ۱۲۵۰۰ قدم در روز)'}</MenuItem>
    </TextValidator>

    <TextValidator
      label={'شغل'}
      placeholder={'شغل شما چیست؟'}
      name={'job'}
      variant={'outlined'}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <WorkIcon/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      validators={['required']}
      errorMessages={['لطفا شغل خود را وارد کنید.']}
      onChange={handleChange}
      value={formValues.job}
    />

    <TextValidator
      select
      label="تعداد جلسات تمرین در هفته"
      name={'num_workout_days'}
      variant={'outlined'}
      fullWidth={true}
      validators={['required']}
      errorMessages={['این فیلد اجباری است.']}
      onChange={handleChange}
      value={formValues.num_workout_days}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FitnessCenterIcon/>
          </InputAdornment>
        ),
      }}
    >
      <MenuItem value={2}>{'۲ روز'}</MenuItem>
      <MenuItem value={3}>{'۳ روز'}</MenuItem>
      <MenuItem value={4}>{'۴ روز'}</MenuItem>
      <MenuItem value={5}>{'۵ روز'}</MenuItem>
      <MenuItem value={6}>{'۶ روز'}</MenuItem>
    </TextValidator>

    <TextValidator
      select
      label="بازه زمانی تمرین"
      name={'workout_time_range'}
      variant={'outlined'}
      fullWidth={true}
      validators={[20, 30].includes(planType) ? ['required'] : []}
      errorMessages={[20, 30].includes(planType) ? ['لطفا بازه زمانی تمرین خود را وارد کنید.'] : []}
      onChange={handleChange}
      value={formValues.workout_time_range}
      helperText={'مقدار وارد شده به معنی "بازه زمانی" است و مدت زمان تمرین نیست.'}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AccessTimeIcon/>
          </InputAdornment>
        ),
      }}
    >
      <MenuItem value={'بازه ۶ الی ۹ صبح'}>{'بازه ۶ الی ۹ صبح'}</MenuItem>
      <MenuItem value={'بازه ۹ الی ۱۲ ظهر'}>{'بازه ۹ الی ۱۲ ظهر'}</MenuItem>
      <MenuItem value={'بازه ۱۲ الی ۱۵'}>{'بازه ۱۲ الی ۱۵'}</MenuItem>
      <MenuItem value={'بازه ۱۵ الی ۱۸'}>{'بازه ۱۵ الی ۱۸'}</MenuItem>
      <MenuItem value={'بازه ۱۸ الی ۲۱'}>{'بازه ۱۸ الی ۲۱'}</MenuItem>
      <MenuItem value={'بازه ۲۱ الی ۲۳'}>{'بازه ۲۱ الی ۲۳'}</MenuItem>
    </TextValidator>

    {[20, 30].includes(planType) &&
      <TextValidator
        label={'سابقه تمرین'}
        placeholder={'سابقه تمرین خود را وارد کنید'}
        name={'workout_history'}
        variant={'outlined'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SportsGymnasticsIcon/>
            </InputAdornment>
          ),
        }}
        fullWidth={true}
        validators={['required']}
        errorMessages={['سابقه تمرین خود را وارد کنید.']}
        onChange={handleChange}
        value={formValues.workout_history}
        className={`${hideInputByPlanType(planType, [20, 30])}`}
      />
    }

    <TextValidator
      label={'آسیب دیدگی و بیماری'}
      placeholder={'آسیب دیدگی یا بیماری خود را وارد کنید'}
      name={'injuries_and_illness'}
      variant={'outlined'}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CoronavirusIcon/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      helperText={'بیماری های متابولیک مانند دیابت، فشار خون، کبد چرب و ... یا' +
        ' آسیب دیدگی های حرکتی مانند دیسک کمر و دیست گردن یا مشکلات زانو، شانه، کتف و ...'}
      validators={['required']}
      errorMessages={['در صورتی که آسیب دیدگی و بیماری ندارید، کلمه "ندارم" یا "خیر" را بنویسید.']}
      onChange={handleChange}
      value={formValues.injuries_and_illness}
    />

    {[10, 30].includes(planType) &&
      <TextValidator
        label={'حساسیت و عادات غذایی'}
        placeholder={'حساسیت و عادات غذایی خود را وارد کنید'}
        name={'food_allergies_and_habits'}
        variant={'outlined'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FastfoodIcon/>
            </InputAdornment>
          ),
        }}
        helperText={'اگر گیاه خوار هستید حتما ذکر کنید'}
        fullWidth={true}
        validators={['required']}
        errorMessages={['لطفا حساسیت و عادات غذایی خود را وارد کنید.']}
        onChange={handleChange}
        value={formValues.food_allergies_and_habits}
      />
    }

    {[10, 30].includes(planType) &&
      <TextValidator
        label={'سابقه مصرف دارو'}
        placeholder={'سابقه مصرف دارو'}
        name={'drug_use_history'}
        variant={'outlined'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MedicationIcon/>
            </InputAdornment>
          ),
        }}
        fullWidth={true}
        helperText={'اگر داروی خاصی مصرف میکنید، حتما ذکر کنید.'}
        validators={['required']}
        errorMessages={['لطفا سابقه مصرف دارو خود را وارد کنید.']}
        onChange={handleChange}
        value={formValues.drug_use_history}
      />
    }

    {[10, 30].includes(planType) &&
      <TextValidator
        select
        label="تمایل به مصرف مکمل"
        name={'want_to_use_supplements'}
        variant={'outlined'}
        fullWidth={true}
        validators={['required']}
        errorMessages={['تمایل خود به مصرف مکمل را وارد کنید.']}
        onChange={handleChange}
        value={formValues.want_to_use_supplements}
        helperText={'منظور مکمل‌های ورزشی مجاز دارو خانه ای میباشد (مانند پودرهای پروتئینی، کراتین و ...)'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ElectricBoltIcon/>
            </InputAdornment>
          ),
        }}
      >
        <MenuItem value={'بله'}>بله</MenuItem>
        <MenuItem value={'خیر'}>خیر</MenuItem>
      </TextValidator>
    }

    {[20, 30].includes(planType) &&
      <TextValidator
        select
        label="محل تمرین"
        name={'workout_place'}
        variant={'outlined'}
        fullWidth={true}
        validators={['required']}
        errorMessages={['محل تمرین خود را انتخاب کنید.']}
        onChange={handleChange}
        value={formValues.workout_place}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SportsGymnasticsIcon/>
            </InputAdornment>
          ),
        }}
        className={`${hideInputByPlanType(planType, [20, 30])}`}
      >
        <MenuItem value={10}>{'باشگاه'}</MenuItem>
        <MenuItem value={20}>{'منزل'}</MenuItem>
      </TextValidator>
    }

    <TextValidator
      label={'هدف'}
      placeholder={'هدف خود را وارد کنید.'}
      name={'goal'}
      variant={'outlined'}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <DescriptionIcon/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      validators={['required']}
      errorMessages={['هدف خود را وارد کنید.']}
      onChange={handleChange}
      value={formValues.goal}
    />

    <TextValidator
      label={'عرض شانه'}
      placeholder={'عرض شانه (سانتی متر)'}
      name={'shoulder_width'}
      variant={'outlined'}
      InputProps={{
        type: 'number',
        startAdornment: (
          <InputAdornment position="start">
            <BoyIcon/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      onChange={handleChange}
      value={formValues.shoulder_width}
    />

    <TextValidator
      label={'دور سینه'}
      placeholder={'دور سینه (سانتی متر)'}
      name={'chest_size'}
      variant={'outlined'}
      InputProps={{
        type: 'number',
        startAdornment: (
          <InputAdornment position="start">
            <Groups3Icon/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      onChange={handleChange}
      value={formValues.chest_size}
    />

    <TextValidator
      label={'دور بازو'}
      placeholder={'دور بازو (سانتی متر)'}
      name={'arm_size'}
      variant={'outlined'}
      InputProps={{
        type: 'number',
        startAdornment: (
          <InputAdornment position="start">
            <FitnessCenterIcon/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      onChange={handleChange}
      value={formValues.arm_size}
    />

    <TextValidator
      label={'دور کمر'}
      placeholder={'دور کمر (سانتی متر)'}
      name={'waist_size'}
      variant={'outlined'}
      InputProps={{
        type: 'number',
        startAdornment: (
          <InputAdornment position="start">
            <TransferWithinAStationIcon/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      onChange={handleChange}
      value={formValues.waist_size}
    />

    <TextValidator
      label={'دور شکم'}
      placeholder={'دور شکم (سانتی متر)'}
      name={'belly_size'}
      variant={'outlined'}
      InputProps={{
        type: 'number',
        startAdornment: (
          <InputAdornment position="start">
            <SettingsAccessibilityIcon/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      onChange={handleChange}
      value={formValues.belly_size}
    />

    <TextValidator
      label={'دور باسن'}
      placeholder={'دور باسن (سانتی متر)'}
      name={'hip_size'}
      variant={'outlined'}
      InputProps={{
        type: 'number',
        startAdornment: (
          <InputAdornment position="start">
            <AirlineSeatLegroomExtraIcon/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      onChange={handleChange}
      value={formValues.hip_size}
    />

    <TextValidator
      label={'دور ران'}
      placeholder={'دور ران (سانتی متر)'}
      name={'thigh_size'}
      variant={'outlined'}
      InputProps={{
        type: 'number',
        startAdornment: (
          <InputAdornment position="start">
            <TransferWithinAStationIcon/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      onChange={handleChange}
      value={formValues.thigh_size}
    />

    <TextValidator
      label={'دور ساق پا'}
      placeholder={'دور ساق پا (سانتی متر)'}
      name={'shin_size'}
      variant={'outlined'}
      InputProps={{
        type: 'number',
        startAdornment: (
          <InputAdornment position="start">
            <AirlineSeatLegroomReducedIcon/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      onChange={handleChange}
      value={formValues.shin_size}
    />

    <TextValidator
      label={'توضیحات'}
      placeholder={'هدف خود و توضیحات تکمیلی را وارد کنید'}
      name={'description'}
      variant={'outlined'}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <DescriptionIcon/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      onChange={handleChange}
      value={formValues.description}
    />

    <FormGroup sx={{margin: '-20px 0 5px 0'}}>
      <FormControlLabel
        control={<Checkbox
          onChange={e => setFormValues({...formValues, hide_images: e.target.checked})}
          value={formValues.hide_images}
        />}
        label="برای ارسال عکس معذوریت دارم"
      />
    </FormGroup>

    <Grid container spacing={2}>
      {!formValues.hide_images &&
        <>
          <Grid item xs={12} sm={12} md={4}>
            <ImagePicker
              cardName="Input Image"
              selectedFile={bodyImageFront}
              title={'تصویر نمای روبرو'}
              inputID={'id_body_front_image'}
              inputName={'body_front_image'}
              onChange={handleFileUpload}
              isUploaded={Boolean(formValues.body_front_image)}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <ImagePicker
              cardName="Input Image"
              selectedFile={bodyImageBack}
              title={'تصویر نمای پشت'}
              inputID={'id_body_back_image'}
              inputName={'body_back_image'}
              onChange={handleFileUpload}
              isUploaded={Boolean(formValues.body_back_image)}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <ImagePicker
              cardName="Input Image"
              selectedFile={bodyImageSide}
              title={'تصویر نمای بغل'}
              inputID={'id_body_side_image'}
              inputName={'body_side_image'}
              onChange={handleFileUpload}
              isUploaded={Boolean(formValues.body_side_image)}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <InputFileUpload
              title={'آپلود تصاویر بیشتر (اختیاری)'}
              loading={Boolean(uploadingExtraImages.length)}
              fullWidth={true}
              multiple={'multiple'}
              onChange={handleExtraBodyImagesChange}
            />

            {!uploadingExtraImages.length && formValues.body_extra_images && formValues.body_extra_images.length ?
              <Typography variant="subtitle2" sx={{display: 'block'}} color={'primary'}>
                {formValues.body_extra_images.length} عکس انتخاب شده
              </Typography> : ''
            }

          </Grid>
        </>
      }
    </Grid>

    <br/>

    <LoadingButton
      sx={{
        margin: '0 0 20px 0',
      }}
      color={'primary'}
      size={'large'}
      fullWidth={true}
      type={'submit'}
      variant="contained"
      endIcon={<KeyboardDoubleArrowLeftIcon/>}
      loading={isSendingAjax || Boolean(sendingAjaxUrls.length) || Boolean(uploadingExtraImages.length)}
    >
      ثبت درخواست
    </LoadingButton>
  </>
}


export default CustomerOrderDietForm
